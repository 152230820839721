//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
  .links,
  .switcher {
    display: none;
  }
}

.nav-sections {
  @include lib-css(background, $navigation__background);
}

.nav-toggle {
  @include lib-icon-font(
                  $icon-menu,
          $_icon-font-size        : 28px,
          $_icon-font-color       : $header-icons-color,
          $_icon-font-color-hover : $header-icons-color-hover
  );
  @include lib-icon-text-hide();
  cursor: pointer;
  display: block;
  font-size: 0;
  left: 15px;
  position: absolute;
  top: 15px;
  z-index: 14;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  
  header .header.content.row{
    flex-wrap: inherit;
    margin: inherit;
  }
  .navigation {
    padding: 0;

    .parent {
      .level-top {
        position: relative;
        @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
        );

        &:after {
          position: absolute;
          right: 7px;
          top: -8px;
        }

        &.ui-state-active {
          @include lib-icon-font-symbol(
                  $_icon-font-content  : $icon-up,
                  $_icon-font-position : after
          );
        }
      }
    }
  }

  .nav-sections {
    -webkit-overflow-scrolling: touch;
    @include lib-css(transition, left 0.3s, 1);
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - #{$active-nav-indent}));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - #{$active-nav-indent});

    .switcher {
      border-top: 1px solid $color-gray82;
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0;
      padding: 0.8rem 3.5rem 0.8rem 2rem;

      .label {
        display: block;
        margin-bottom: $indent__xs;
      }

      &:last-child {
        border-bottom: 1px solid $color-gray82;
      }
    }

    .switcher-trigger {
      strong {
        position: relative;
        @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
        );

        &:after {
          position: absolute;
          right: -40px;
          top: -16px;
        }
      }

      &.active strong {
        @include lib-icon-font-symbol(
                $_icon-font-content  : $icon-up,
                $_icon-font-position : after
        );
      }
    }

    .switcher-dropdown {
      @include lib-list-reset-styles();
      padding: $indent__s 0;
    }

    .header.links {
      @include lib-list-reset-styles();
      border-bottom: 1px solid $color-gray82;

      li {
        font-size: 1.6rem;
        margin: 0;

        &.greet.welcome {
          border-top: 1px solid $color-gray82;
          font-weight: 700;
          padding: 0.8rem $indent__base;
        }

        > a {
          border-top: 1px solid $color-gray82;
        }
      }

      a,
      a:hover {
        @include lib-css(color, $navigation-level0-item__color);
        @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
        @include lib-css(font-weight, $font-weight__bold);
        display: block;
        padding: 0.8rem $indent__base;
      }

      .header.links {
        border: 0;
      }
    }
  
  }

  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    .page-wrapper {
      @include lib-css(transition, left 0.3s, 1);
      height: 100%;
      left: 0;
      overflow: hidden;
      position: relative;
    }

    body {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
  }

  .nav-open {
    .page-wrapper {
      left: 80%;
      left: calc(100% - #{$active-nav-indent});
    }

    .nav-sections {
      $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

      @include lib-css(box-shadow, $_shadow, 1);
      left: 0;
      z-index: 99;
    }

    .nav-toggle {
      &:after {
        background: rgba(0, 0, 0, $overlay__opacity);
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .nav-sections-items {
    @include lib-clearfix();
    position: relative;
    z-index: 1;
  }

  .nav-sections-item-title {
    background: darken($navigation__background, 5%);
    border: solid darken($navigation__background, 10%);
    border-width: 0 0 1px 1px;
    box-sizing: border-box;
    float: left;
    height: 71px;
    padding-top: 24px;
    text-align: center;
    width: 33.33%;

    &.active {
      background: transparent;
      border-bottom: 0;
    }

    .nav-sections-item-switch {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-sections-item-content {
    @include lib-clearfix();
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    margin-top: 71px;
    width: 100%;

    &.active {
      display: block;
    }

    padding: $indent__m 0;
  }

  header .category-menu-wrapper.active .main-menu .ves-megamenu li.level0 > a > span.opener{
    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.95 19.27'%3E%3Ctitle%3Edropdown2%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Laag_1-2' data-name='Laag 1'%3E%3Cpath fill='%23333' d='M15.35.67.67%2C15.35A2.29%2C2.29%2C0%2C0%2C0%2C3.92%2C18.6L17%2C5.51%2C30%2C18.6a2.29%2C2.29%2C0%2C1%2C0%2C3.24-3.24L18.6.67a2.29%2C2.29%2C0%2C0%2C0-3.24%2C0Zm0%2C0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      transform: rotate(180deg);
      position: relative;
      top: 19px;
    }
    &.item-active{
      &::before {
        transform: rotate(0deg);
        position: relative;
        top: 0;
      }
    }

  }

  @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .nav-toggle {
    display: none;
  }

  

  .nav-sections {
    @include lib-vendor-prefix-flex-shrink(0);
    @include lib-vendor-prefix-flex-basis(auto);
    margin-bottom: $indent__xxl;
  }

  .nav-sections-item-title {
    display: none;
  }

  .nav-sections-item-content {
    display: block !important;
  }

  .nav-sections-item-content > * {
    display: none;
  }

  .nav-sections-item-content {
    > .navigation {
      display: block;
    }
  }

  @include lib-main-navigation-desktop();

  .panel.header {
    .links,
    .switcher {
      display: inline-block;
    }
  }
}

#layered-filter-block .filter-options-item {
  &.active .filter-options-title::after {
    transform: rotate(180deg);
    top: 10px;
  }
  .filter-options-title::after{
    position: relative;
    top: 3px;
    display: inline-block;
    width: 11px;
    height: 11px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.95 19.27'%3E%3Ctitle%3Edropdown2%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Laag_1-2' data-name='Laag 1'%3E%3Cpath fill='%23333' d='M15.35.67.67%2C15.35A2.29%2C2.29%2C0%2C0%2C0%2C3.92%2C18.6L17%2C5.51%2C30%2C18.6a2.29%2C2.29%2C0%2C1%2C0%2C3.24-3.24L18.6.67a2.29%2C2.29%2C0%2C0%2C0-3.24%2C0Zm0%2C0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  
  }
}

// Ves Megamenu

.main-menu .level0.nav-item {
  position: static !important;
  }
  
  header .main-menu .navigation .ves-megamenu .nav-item.level1 {
  display: flex;
  flex-direction: column;
  }
  
  .category-menu-wrapper .ves-megamenu .submenu-inner .mega-col .nav-anchor + .submenu span {
  text-transform: initial;
  font-weight: 500;
  }
  
  header .main-menu .navigation .ves-megamenu .nav-item.level1 a+ .submenu .ves-megamenu .col-xs-12 {
  padding: 0;
  }
  
  header .main-menu .navigation .ves-megamenu > li > .submenu {
  z-index: 5;
  }
  
  header .main-menu .navigation .ves-megamenu .level1 .nav-item.level1 > .submenu {
  position: static !important;
  }
  
  header .main-menu .navigation .ves-megamenu > li > .submenu {
  z-index: 5 !important;
  }
  
  header .main-menu .navigation .ves-megamenu > li.level0:hover:after {
   z-index: 4;
   width: calc(66.66% + 16px);
  }
