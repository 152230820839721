
#btn-show-filter,
.close-icon{
    display: none;
}

@include max-screen($screen__m) {
    body .sidebar.sidebar-main {
        top: -2000px;
    }

    body .category-filter .items{
        margin-left: 0;
        padding-left: 0;
        padding-top: 10px;
        border-left: 0;
        .item{
            margin: 5px 0 4px 0;
        }
    }

    #btn-show-filter{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        width: 100%;
        font-size: 16px;
        background-color: #AE9B80;
        border-color:#AE9B80;
        padding: 8px 0.75rem 8px;
        &:hover, &:visited{
            background-color: #857257;
            border-color:#857257;
        }
    }

    body.offcanvas-open {

        .page-wrapper>.page-header {
            z-index: -1;
        }

        #maincontent.page-main {
            margin-top: 0;
        }

        .page-layout-2columns-left .sidebar-main {
            width: 100%;
            padding-right: 0;

        }

        .sidebar.sidebar-main {
            position: fixed;
            inset: 15px;
            top: 0;
            right: 15px;
            left: 15px;
            z-index: 1000;
            overflow: hidden;
            padding: 20px 24px 160px;
            border-radius: 8px;
            background: #FFFFFF;
            display: block;
            top: 0;
            left: 0;
            right: 10px;
            height: 100%;
            width: calc(100%);
            overflow: auto;

            #layered-filter-block {
                margin-top: 0;
                .close-icon {
                    display: block;
                    position: absolute;
                    top: 22px;
                    right: 16px;
                    .icon-close{
                        font-size: 28px;
                        color: #002C6B;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:after {
                        margin-bottom: 20px;
                        display: inline-block;
                        float: right;
                        height: 20px;
                        width: 20px;
                    }
                }

                .block-title.filter-title{
                    display: block;
                    font-size: 25px;
                    color: #002C6B;
                }

                .block-content {
                    &.filter-content {
                        margin-top: 10px;
                    }

                    .filter-options-item {
                        &.active {
                            .filter-options-title {
                                &:after {
                                    top: 13px;
                                    right: 0;
                                }
                            }
                        }

                        .filter-options-content{
                            margin-bottom: 16px;
                            .items .item{
                                margin: 5px 0 5px 0;
                                label {
                                    padding-left: 10px;
                                }
                            } 
                        }

                        .filter-options-title {
                            padding: 5px 0;
                            &:after {
                                top: 0;
                                right: 3px;
                                font-family: 'icomoon';
                                content: "\e901";	
                            }
                            &:hover {
                                cursor: pointer;
                                color:#002C6B;
                                &:after {
                                    color:#002C6B;
                                }
                            }
                        }
                    }


                }

            }
        }


    }

}
