.checkout-payment-method .buckaroo_magento2_afterpay20 {
    .billing-address-form{
     width: 100%;
     max-width: inherit;
     .fieldset.payment{
       display: block;
       .control{
         margin-top: 10px;
       }
       #buckaroo_magento2_afterpay_TermsCondition{
         margin-left: 0;
         margin-right: 5px;
       }
     }
   }
 }

 .checkout-payment-method .checkout-billing-address .billing-address-same-as-shipping-block{
    #billing-address-same-as-shipping-shared{
        height: inherit;
        margin-left: 22px;
        margin-right: 5px;
    }
 }

 [name="amastyShippingBeforeAttributes.test"].field {
  display: none !important;
}