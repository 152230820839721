.cms-homepage {
  .page-main.page-main-fluid{
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px !important;
    padding-left: 20px;
    padding-right: 20px;
    width: auto  !important;
  }
  .columns {
    margin-top: 30px;
  }
  .ves-container{
    .padding-right{
      .col-inner{
        display: flex;
        align-items: end;
        height: 100%;
        min-height: 450px;
        > div{
          width: 100%;
        }
        .widget.block{
          margin-bottom: 31px;
          font-family: "Overpass", sans-serif;
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1;
          color: #fff;
          background: #002C6B;
          padding: 27px 25px;
          border-top: 14px solid #35609F;
          a, a span{
            color: white;
          }
        }
      }
    }
  }
  .usp-container {
    margin-top: 0;

    .usp-title {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__xxl);
      @include lib-css(font-weight, $font-weight__bold);
      margin-bottom: 36px;
      line-height: $line-height__s;
      color: $theme__primary__color;
    }

    strong {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__m);
      @include lib-css(font-weight, $font-weight__bold);
      display: block;
      padding-bottom: 8px;
      line-height: $line-height__xs;
    }

    span {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__base);
      @include lib-css(font-weight, $font-weight__regular);
      display: inline-block;
      padding-bottom: 4px;
      line-height: $line-height__s;
    }

    .content {
      ul {
        @include lib-list-reset-styles();

        li {
          @include lib-css(font-family, $font-family__overpass);
          @include lib-font-size($font-size__base);
          @include lib-css(font-weight, $font-weight__regular);
          height: auto;
          padding-left: 30px;
          margin-bottom: 8px;
          line-height: $line-height__s;
          background-image: url(lib-icon-svg-url($icon-base-usp, $theme__primary__color));
          background-size: 18px 18px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .icon-arrow-right {
    background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    margin-left: 11px;

    &.primary {
      background: url(lib-icon-svg-url($icon-base-arrow-right, $theme__primary__color)) no-repeat;
    }
  }

  .ves-container {
    margin-bottom: 0;
  }
}

.homepage-category-row{
  .col-inner{
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.homepage-blok {
  margin-bottom: 30px;

  .content {
    min-height: 357px;

    .title {
      display: none;
    }
  }

  .subtitle {
    @include lib-css(font-family, $font-family__overpass);
    @include lib-font-size($font-size__xxl);
    @include lib-css(font-weight, $font-weight__bold);
    line-height: $line-height__xs;
    color: $color-white;
    background: $theme__primary__color;
    padding: 27px 25px;
    border-top: 14px solid $theme__primary__color__light;
  }

  .small {
    .content {
      height: 240px;
      min-height: 1px;
    }

    .subtitle {
      @include lib-font-size($font-size__l);
      line-height: $line-height__xs;
      padding: 25px 15px;
      border-top: 10px solid $theme__primary__color__light;
    }
  }

  &.secondary {
    .content {
      background: $color-gray-light0;
      padding: 20px 26px;
    }

    .subtitle:not(.kiyoh-container) {
      background: $theme__primary__color;
      border-color: $theme__primary__color__light;
    }

    .small {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__m);
      @include lib-css(font-weight, $font-weight__regular);
      line-height: $line-height__l;

      .content {
        padding: 25px 23px;
      }

      a {
        @include lib-css(font-weight, $font-weight__bold);
        line-height: $line-height__xs;
        color: $color-gray20;
      }
    }
  }
}

.homepage-banners {
  .banner-left {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-box-align(center);
    justify-content: center;
    height: 150px;
    background-color: $theme__secondary__color;

    .highlight {
      @include lib-font-size($font-size__m);
      @include lib-css(font-family, $font-family__overpass);
      @include lib-css(font-weight, $font-weight__bold);
      background-color: $theme__primary__color;
      text-transform: uppercase;
      padding: 8px 18px;
      top: 20px;
      left: 15px;
      position: absolute;
    }

    .text {
      &:after {
        background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
        content: " ";
        height: 25px;
        width: 25px;
        display: inline-block;
        top: 9px;
        left: 5px;
        position: relative;
      }
    }
  }

  .banner-right {
    height: 150px;
    background-image: url('../images/banner-right.png');

    .block-content {
      height: 100%;
    }

    .banner-overlay {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-prefix-flex-direction(column);
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: rgba($color-gray20, .9);
      padding-left: 32px;
      padding-right: 32px;

      div {
        @include lib-css(font-family, $font-family__overpass);
        @include lib-font-size($font-size__l);
        @include lib-css(font-weight, $font-weight__regular);

        span {
          color: $color-orange1;
        }
      }
    }
  }

  a {
    color: $color-white;
    text-decoration: none;
  }
}

.homepage-tips-container {
  margin-top: 80px;
  margin-bottom: 40px;

  .row-inner {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-box-align(stretch);

    .col-inner {
      height: 100%;
    }

    .ves-container {
      height: 100%;
      div {
        height: 100%;
      }
    }

    .ves-inner {
      height: 100%;
    }
  }

  .homepage-tips {
    background: $color-gray20;
    margin-top: 0;
    padding-right: 0;

    .row-inner {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-box-align(stretch);

      .img-container {
        position: relative;
        max-width: 300px;
        padding-right: 0;

        img {
          height: 100%;
        }

        .arrow {
          width: 45px;
          height: 43px;
          z-index: 1;
          position: absolute;
          right: -26px;
          top: 30px;
          overflow: auto;
          left: inherit;
          margin-left: inherit;
          bottom: inherit;
        }

        .col-inner {
          border-right: 10px solid $color-gray64;
          height: 100%;
          background-size: cover;
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .cms-homepage {
    .homepage-tips-container {
      overflow: hidden;

      > div {
        > .ves-inner {
          > .row {
            > .row-inner {
              @include lib-vendor-prefix-flex-direction(column);
            }
          }
        }
      }

      .homepage-tip {
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
        }

        .row-inner {
          @include lib-vendor-prefix-flex-wrap(wrap);
          padding-right: 15px;

          .img-container {
            height: 30vw;
            max-width: 100%;

            .col-inner {
              border: none;
            }
          }
        }

        .content {
          margin-left: 15px;
        }
      }
    }

    .homepage-banners {
      .banner-left {
        .highlight {
          @include lib-font-size($font-size__base);
          line-height: $line-height__s;
          top: 7px;
        }
      }
    }
  }
}

a {
  &.button {
    @include lib-button-primary();
    text-decoration: none;
  }
}
