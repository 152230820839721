.upsell {
    margin-bottom: 0;

    .block-title {
        #block-upsell-heading {
            padding-left: 40px;
        }
    }
}

.products-upsell {
    margin-bottom: 0;

    .products {
        margin: 0;
        padding-left: 37px;
        max-width: 530px;

        .product-item {
            margin-bottom: 0;
            width: 50%;

            .product-item-info {
                margin: 0;
            }
        }
    }
}

@include max-screen($screen__l) {
    .product-upsell-container {
        float: left;
    }

    .upsell {
        margin-bottom: 10px;

        .block-title {
            margin-bottom: 0 !important;

            #block-upsell-heading {
                padding-left: 0;
            }
        }
    }

    .products-upsell {
        .products {
            padding-left: 20px;
            max-width: 530px;
        }
    }
}
