//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80 !default;
$minicart__padding-horizontal : $indent__base !default;

$minicart-qty__height         : 24px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    width: 404px;
    margin-top: 0;
    border: 0;
    right: 0;

    &:before,
    &:after {
        display: none;
    }

    .block-title {
        display: none;
    }

    .items-total {
        float: left;
        margin: 0 $indent__s;

        .count {
            @include lib-css(font-weight, $font-weight__bold);
        }
    }

    .subtotal {
        border-bottom: 1px solid $minicart__border-color;
        padding: $indent__s 15px $indent__s 26%;

        .label {
            width: 50%;
            text-align: left;
            padding: 0;
            @include lib-css(font-weight, $font-weight__bold);
        }

        &.shippingcosts {
            .label,
            .price {
                @include lib-css(font-weight, $font-weight__regular);
            }
        }
    }

    .amount {
        text-align: right;
        float: right;

        .price {
            @include lib-css(font-weight, $font-weight__bold);
        }
    }

    .subtitle {
        display: none;

        &.empty {
            @include lib-font-size($font-size__base);
            display: block;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        > .actions {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            margin-top: 13px;
            padding: 0 15px;

            > .primary {
                width: 50%;

                .action {
                    &.checkout {
                        @include lib-button-checkout();
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            > .secondary {
                width: 50%;
                text-align: center;

                .action {
                    color: $color-gray20;
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-minicart,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_options-selector : '.block-minicart',
        $_dropdown-list-item-padding : 17px 15px 9px 15px,
        $_dropdown-list-item-hover : $color-white,
        $_dropdown-list-background : $color-white,
        $_dropdown-list-pointer : false,
        $_dropdown-list-margin-top : false,
        $_dropdown-list-border : false
    );
    margin: 0;
    height: 65px;
    width: 100%;

    &:hover,
    &.active {
        background-color: $color-white;
    }

    .product {
        .actions {
            display: none;
        }
        .product-image-container{
            width: 70px !important;
            .product-image-photo{
                max-width: inherit;
                max-height: inherit;
                img{
                    width: 100% !important;
                }
            }
        }
    }

    .action {
        &.close {
            display: none;
        }

        &.showcart {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            position: relative;
            white-space: nowrap;
            text-decoration: none;
            padding: 0 12px;
            height: 100%;
            width: 100%;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter {
                &.qty {
                    @include lib-css(background, $theme__secondary__color);
                    @include lib-css(color, $color-white);
                    @include lib-font-size($font-size__s);
                    border-radius: 50%;
                    display: inline-block;
                    text-align: center;
                    width: 20px;
                    color: $color-white;
                    position: absolute;
                    left: 26px;
                    top: 13px;
                    padding: 1px;

                    &.empty {
                        display: none;
                    }

                    .loader {
                        > img {
                            @include lib-css(max-width, $minicart-qty__height);
                        }
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }

            &:before {
                @include lib-icon-svg($icon-base-cart, $color-white);
                display: inline-block;
                height: 25px;
                width: 26px;
                flex-shrink: 0;
            }

            &:after {
                @include lib-icon-svg($icon-base-arrow-down, $color-white);
                display: inline-block;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 20px;
                right: 10px;
            }

            &.active,
            &:hover {
                &:before {
                    @include lib-icon-svg($icon-base-cart, $theme__primary__color);
                }

                &:after {
                    @include lib-icon-svg($icon-base-arrow-down, $theme__primary__color);
                }
            }

            &.active {
                &:after {
                    @include lib-icon-svg($icon-base-arrow-up, $theme__primary__color);
                }
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}

.minicart-items-wrapper {
    padding: 0;
    height: 100% !important;
    border: 0;
    border-bottom: 1px solid $minicart__border-color;
}

.col-minicart {
    padding-right: 20px;
    max-width: 104px;
    float: right;
    height: 65px;
}

.minicart-items {
    @include lib-list-reset-styles();
    max-height: 65vh;
    overflow-y: scroll;

    .minicart-items {
        max-height: 100%;
        overflow: visible;
    }

    .product-item {
        cursor: auto;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        width: 30%;
        padding-left: 5px;

        .price-container {
            display: inline-block;
            float: right;
        }

        .label {
            display: inline-block;
            width: 4.5rem;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content     : $icon-down,
                $_icon-font-size        : 28px,
                $_icon-font-line-height : 16px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            cursor: pointer;
            position: relative;

            &:after {
                position: static;
                right: $indent__base;
                top: 0;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }
    }

    .product-item-photo {
        width: 24%;
    }

    .product-item-name {
        @include lib-css(font-weight, $font-weight__regular);
        width: 46%;
        padding-top: 0;
        margin: 0 0 $indent__s;

        a {
            @include lib-css(color, $color-gray20);
            line-height: $line-height__l;
        }

        .price {
            color: $color-green2;
        }
    }

    .product-item-details {
        @include lib-vendor-prefix-display(flex);

        .price {
            @include lib-font-size($font-size__m);
            @include lib-css(font-weight, $font-weight__bold);
        }

        .weee[data-label] {
            @include lib-font-size($font-size__xs);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            width: 30px;
            display: inline-block;
            margin-top: 0;
            text-align: center;
            .label {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__bold);
                width: auto;
                &:after {
                    display: none;
                }
            }
        }
    }

    .product.options {
        .tooltip {
            &.toggle {
                @include lib-icon-font(
                                $icon-down,
                        $_icon-font-size: 28px,
                        $_icon-font-line-height: 28px,
                        $_icon-font-text-hide: true,
                        $_icon-font-margin: -3px 0 0 7px,
                        $_icon-font-position: after
                );

                .details {
                    display: none;
                }
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        width: 40px;
    }

    .update-cart-item {
        @include lib-font-size($font-size__xs);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                $icon-settings,
                $_icon-font-size         : 28px,
                $_icon-font-line-height  : 28px,
                $_icon-font-text-hide    : true,
                $_icon-font-color        : $color-gray19,
                $_icon-font-color-hover  : $color-gray19,
                $_icon-font-color-active : $color-gray19
            );
        }

        &.delete {
            @include lib-icon-font-symbol(
                $_icon-font-content : $icon-trash
            );
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .col-minicart {
        padding-right: 15px;
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(center);
        justify-content: center;
    }

    .minicart-wrapper {
        background-color: transparent;
        height: 40px;

        &.active {

            .action {
                &.showcart {
                    background-color: $theme__secondary__color;

                    &:before {
                        @include lib-icon-svg($icon-base-cart, $color-white);
                    }

                    .counter {
                        &.qty {
                            background: $color-white;
                            color: $theme__primary__color;
                        }
                    }
                }
            }

            .block-minicart {
                width: 100vw;
            }
        }

        .action {
            &.showcart {
                width: calc(100% - 15px);
                padding: 0 9px;

                &:before {
                    @include lib-icon-svg($icon-base-cart, $theme__primary__color);
                }

                &:after,
                &:hover:after {
                    display: none;
                }

                .counter {
                    &.qty {
                        @include lib-font-size($font-size__xs);
                        @include lib-css(font-weight, $font-weight__bold);
                        color: $theme__primary__color;
                        border: 1px solid $theme__primary__color;
                        background: $color-white;
                        position: absolute;
                        left: 22px;
                        padding: 1px 0;
                        border-radius: 50%;
                        width: 19px;
                        top: 5px;
                    }
                }

                &:hover {
                    .counter {
                        color: $theme__primary__color;
                    }
                }
            }
        }

        .button-primary {
            @include lib-button-primary();
        }
    }

    .opc-estimated-wrapper {
        .minicart-wrapper {
            background-color: transparent;
            margin: 10px 0 5px;
        }
    }

    aside {
        &.custom-slide {
            &._show {
                left: 0;
                top: 105px;
            }
        }
    }
}

@include max-screen($screen__l) {
    .col-minicart {
        padding-left: 0;
        padding-right: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        .block-minicart {
            width: 390px;
        }
    }
}

@include min-screen($screen__l) {
    .minicart-wrapper {
        .action {
            &.showcart {
                &:after {
                    display: block;
                }
            }
        }
    }
}
