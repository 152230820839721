.fotorama__thumb-border {
    @extend .fotorama-print-background;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 1px solid $theme__primary__color;
    background-image: linear-gradient(to bottom right, $slider__transparant-background-light, $slider__transparant-background-dark);
    backface-visibility: hidden;
    z-index: 9;

    .fotorama__nav--dots & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }
}

// vergroten borders
.fotorama__nav-wrap{
    .fotorama__dot {
        width: 10px;
        height: 10px;
    }
}
.fotorama__nav--dots .fotorama__nav__frame {
    width: 25px;
}
