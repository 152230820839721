$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;

    &-amount {
        display: none;
    }

    &-products {
        @extend .abs-add-clearfix;
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(center);
        margin-bottom: $indent__xl;
        padding: 0 15px;
        text-align: center;

        .pages {
            @include lib-vendor-prefix-order(1);
            margin-left: auto;
            display: block;

            .products {
                &.wrapper {
                    ~ & {
                        display: block;
                    }
                }
            }

            .action {
                width: auto;
            }

            .pages-item-next,
            .pages-item-previous {
                @include lib-font-size($font-size__base);
            }
        }

        .limiter {
            display: none;
        }
    }

    &-sorter {
        display: inline-block;
        position: relative;

        .sorter-label {
            color: $color-gray48;
        }

        .sorter-options {
            @include lib-vendor-prefix-appearance(none);
            background: $color-white;
            background-size: 30px 60px;
            border: 1px solid $color-gray82;
            height: 34px;
            padding-right: 25px;
            text-indent: .01em;
            text-overflow: '';
            border-radius: 0;
            margin-left: 13px;
            padding-left: 11px;
            width: 185px;
        }

        &:after {
            @include lib-icon-svg($icon-base-caret-down, $color-gray20);
            display: inline-block;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 8px;
            top: 4px;
            pointer-events: none;
        }
    }
}

.sorter {
    .products {
        &.wrapper {
            ~ .toolbar & {
                display: none;
            }
        }
    }
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    display: none;

    span {
        display: none;
    }
}

.sorter {
    .sort {
        &-desc,
        &-asc {
            &:before {
                width: 15px;
                height: 15px;
                display: inline-block;
                position: absolute;
                right: -30px;
                top: 5px;
            }
        }

        &-desc {
            &:before {
                @include lib-icon-svg($icon-base-arrow-down, $color-black);
            }
        }

        &-asc {
            &:before {
                @include lib-icon-svg($icon-base-arrow-up, $color-black);
            }
        }
    }
}

.modes {
    display: none;
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products {
        .toolbar & {
            display: none;
        }
    }
}

@include max-screen($screen__m) {
    .toolbar-products {
        @include lib-vendor-prefix-flex-direction(column);

        .pages {
            margin-left: 0;
            margin-top: 15px;
        }

        .toolbar-sorter {
            display: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products{
            &.wrapper {
                ~ & .pages {
                    float: left;
                }
            }
        }

        &-amount {
            float: left;
        }
    }

    .sorter {
        float: left;
    }

    .limiter {
        float: right;

        .products{
            &.wrapper {
                ~ .toolbar & {
                    display: block;
                }
            }
        }
    }
    
    .pages {
        .items {
            float: right;
        }
    }
}