.page-wrapper .breadcrumbs {
    @include lib-breadcrumbs();
    position: absolute;
    margin-top: 0;
    top: 165px;
    max-width: 100%;
    padding: 0;

    strong {
        @include lib-css(font-weight, $font-weight__bold);
    }

    ul {
        li {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__semibold);
            line-height: $line-height__xl;
            color: $color-gray20;

            a,
            a:visited {
                @include lib-css(font-weight, $font-weight__regular);
                color: $color-gray20;
            }
        }
    }

    .items {
        @include lib-css(font-family, $font-family__overpass);
        @include lib-vendor-prefix-display(flex);
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;

        .item {
            height: 44px;
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);

            &:not(:last-child) {
                &:after {
                    transform: rotate(-90deg);
                    content: '\e607';
                    line-height: $line-height__s;
                    margin-right: 0;
                    margin-top: 6px;
                    width: 18px;
                }
            }

            &-minicart {
                padding-right: 20px;
                max-width: 104px;
                align-self: right;
                float: right;
            }
        }
    }

    @if $breadcrumbs-separator__symbol != '' and $breadcrumbs-separator__symbol != false {
        .item:not(:last-child) {
            &:after {
                width: 18px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .breadcrumbs {
        display: none;
    }
}

.breadcrumb-spacer {
    height: 24px;
}