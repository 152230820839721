.search-autocomplete {
    width: 100% !important;
    top: 80px;
    max-height: 80vh;
    overflow-y: auto;

    .autocomplete-list {
        &.term {
            display: none;
        }
    }

    dl {
        dd {
            &:hover,
            &.selected {
                background-color: $color-gray-light0
            }
        }
    }

    .smile-elasticsuite-autocomplete-result {
        background-color: $color-white;
        border-top: 5px solid $theme__primary__color__light;
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-wrap(wrap);

        .autocomplete-list {
            &.term {
                display: none;
            }
        }

        dl {
            margin-bottom: 0;

            &.product {
                @include lib-vendor-prefix-display(inline-flex);
                @include lib-vendor-prefix-flex-wrap(wrap);
                width: 75%;
                padding: 0;

                dd {
                    width: 33.33%;
                    border: 0;
                    margin: 0;
                    padding: 0;
                    display: inline-grid;
                    cursor: pointer;

                    .product-item-container {
                        padding: 15px;
                        border-right: 1px solid $color-gray-middle-border;
                        border-bottom: 1px solid $color-gray-middle-border;
                    }
                }

                .title-product {
                    display: none;
                }
            }

            &.category {
                width: 25%;

                .title-category {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-family, $font-family__overpass);
                    @include lib-css(font-weight, $font-weight__semibold);
                    color: $theme__primary__color;
                    margin-top: 27px;
                    margin-bottom: 10px;
                    padding: 0 15px 4px 15px;
                }

                dd,
                dt {
                    padding: 0 15px;
                    border: none;
                }

                dd {
                    &:last-of-type {
                        margin-bottom: 30px;
                    }
                }

                .category-mini-crumb {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-family, $font-family__overpass);
                    @include lib-css(font-weight, $font-weight__regular);
                    display: inline-block;
                    font-style: normal;

                    &:after {
                        content: '\002f';
                        padding-left: 5px;
                        padding-right: 3px;
                    }
                }

                .qs-option-name {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-family, $font-family__overpass);
                    @include lib-css(font-weight, $font-weight__semibold);
                }
            }
        }

        .product-image-box {
            width: 100%;
            height: 210px;
            background-size: cover;
            background-position: center;
        }

        .product-shop {
            padding-top: 10px;
            width: 100%;

            .product-primary {
                @include lib-font-size($font-size__base);
                @include lib-css(font-family, $font-family__overpass);
                @include lib-css(font-weight, $font-weight__semibold);
            }

            .product-secondary {
                padding-top: 5px;

                .price-box {
                    padding: 0;
                    @include lib-vendor-prefix-flex-wrap(row);

                    .price-box {
                        .special-price {
                            color: $product-label-1;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        .search-all {
            display: block;
            width: 100%;
            padding: 20px;
            text-align: center;
        }
    }
}

@include max-screen($screen__m) {
    .search-autocomplete {
        top: 140px;

        .smile-elasticsuite-autocomplete-result {
            display: block;
            padding-bottom: 90px;

            dl {
                &.product,
                &.category {
                    width: 100%;

                    dd {
                        width: 100%;
                    }
                }
            }
        }
    }

    .menu-overlay.active {
        position: fixed;
    }
}
