.footer {
  &-contact-wrapper {
    padding: 22px 0 0 0;
    background: $color-gray-darken5;
    color: $color-white;
  }

  &-contact-blocks {
    min-height: 103px;
    display: flex;

    > li {
      @include lib-css(font-family, $font-family__overpass);
      padding-right: 40px;

      a {
        color: $color-white;
        text-decoration: none;
      }

      &:before {
        @include lib-font-size($font-size__xl);
        content: '';
        float: left;
        display: block;
        width: 38px;
        height: 38px;
        line-height: 38px;
        color: $color-gray-darken5;
        border-radius: 20px;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 20px;
        text-align: center;
        margin: 0 20px 54px 0;
      }

      &.footer-contact-bel:before {
        background-image: url(lib-icon-svg-url($icon-base-phone, $color-gray-darken5));
      }

      &.footer-contact-mail:before {
        background-image: url(lib-icon-svg-url($icon-base-mail, $color-gray-darken5));
      }

      &.footer-contact-chat:before {
        background-image: url(lib-icon-svg-url($icon-base-chat, $color-gray-darken5));
      }
    }

    &.mobile {
      margin: 0 15px;
      width: auto;

      > .footer-contact-block {
        background-color: $theme__primary__color;
        margin-bottom: 10px;
        padding: 10px 15px;
        float: none;
        width: 100%;

        &.footer-contact-bel:before {
          @include lib-icon-svg($icon-base-phone, $color-white);
        }

        &.footer-contact-mail:before {
          @include lib-icon-svg($icon-base-mail, $color-white);
        }

        &.footer-contact-chat:before {
          @include lib-icon-svg($icon-base-chat, $color-white);
        }

        a {
          color: $color-white;
        }

        span {
          @include lib-font-size($font-size__m);
        }

        &:before {
          width: 20px;
          height: 20px;
          margin: 0 12px 0 0;
          background: none;
          color: $color-white;
          line-height: 30px;
        }
      }
    }

    span {
      display: block;
      padding-top: 4px;
      @include lib-font-size($font-size__xl);
      @include lib-css(font-weight, $font-weight__semibold);
    }
  }
}

@include max-screen($screen__m) {
  .footer {
    &-contact-wrapper {
      background-color: $color-gray-light0;

      .hidden-xs {
        display: none !important;
      }

      .footer-title {
        @include lib-font-size($font-size__xl);
        @include lib-css(font-weight, $font-weight__bold);
        @include lib-css(font-family, $font-family__overpass);
        margin-bottom: 22px;
        color: $color-gray-darken5;
      }

      .footer-contact-blocks {
        background-color: $color-gray-light0;
        flex-wrap: wrap;
      }
    }
  }
}
