// Mixins
@import "mixins/media-queries";

@import url("https://cdn.icomoon.io/45713/annaserviesnl/style.css?q3m2nz");

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI
@import 'vendor/font-awesome/fontawesome'; // Font awesome
@import "vendor/font-awesome/solid.scss";

// Theme variables
@import 'variables';
// Theme functions
@import 'functions';

// Reset default styles with magento-reset
@import 'blocks/reset';

// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/contact';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/offcanvas';
@import 'blocks/pages'; // Theme pager
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';
@import 'blocks/postnl';
@import 'blocks/usp';
@import 'blocks/qualitymarks';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Pages
@import 'pages/homepage.scss';
@import 'pages/contact.scss';
@import 'pages/404.scss';

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Checkout/styles/module/checkout';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_ConfigurableProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Ves_Megamenu/styles/module';
@import '../Ves_PageBuilder/styles/module';
@import '../Interactivated_Customerreview/styles/module';
@import '../Smile_ElasticsuiteCatalog/styles/module';
@import '../Smile_ElasticsuiteCore/styles/module';
@import '../Lof_Formbuilder/styles/module';
@import '../Magefan_Blog/styles/module';

// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';
@import '../Ves_PageBuilder/styles/widgets';
