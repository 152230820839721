.block {
  &.ves-widget {
    .block-title {
      margin-bottom: 28px;
      padding-top: 8px;

      strong {
        @include lib-font-size($font-size__xxxl);
        @include lib-css(font-family, $font-family__overpass);
        @include lib-css(font-weight, $font-weight__bold);
      }
    }

    &.menu-title {
      padding-bottom: 24px;
      margin: 15px 0 24px;
      border-bottom: 1px solid $color-gray82;

      .block-content {
        @include lib-font-size($font-size__xxl);
        @include lib-css(font-family, $font-family__overpass);
        @include lib-css(font-weight, $font-weight__bold);
      }
    }

    .block-content {
      @include lib-css(font-weight, $font-weight__regular);
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__m);
      line-height: $line-height__s;
    }
  }
}

.cms-homepage {
  .block {
    &.ves-widget {
      .block-title {
        border-bottom: 1px solid $color-gray64;
        padding-bottom: 20px;
        margin-top: 16px;
      }
      
      &.homepage-brand-slider {
        text-align: center;
      }
    }

    &.block-products-list {
      margin-bottom: 0;

      .block-title {
        margin-top: 16px;
        padding-bottom: 20px;

        strong {
          @include lib-css(font-weight, $font-weight__bold);
          @include lib-font-size($font-size__xxxl);
        }
      }

      .block-content {
        padding: 0;
        display: block;

        .product-items {
          margin: 0;
        }
      }
    }
  }

  .homepage-banners {
    .block {
      &.ves-widget {
        .block-content {
          @include lib-font-size($font-size__xxl);
          @include lib-css(font-weight, $font-weight__semibold);
          color: $color-white;
        }
      }
    }
  }

  .homepage-tips-container {
    .block {
      &.ves-widget {
        margin-bottom: 0;

        .content {
          @include lib-font-size($font-size__m);
          @include lib-css(font-family, $font-family__overpass);
          @include lib-css(font-weight, $font-weight__regular);
          line-height: $line-height__s;
          padding: 20px 12px 60px 3px;
          color: white;
          position: inherit;
          height: auto;

          .title {
            position: relative;
            z-index: 1;

            strong {
              @include lib-font-size($font-size__xxl);
              @include lib-css(font-weight, $font-weight__bold);
              line-height: $line-height__s;
              word-wrap: break-word;
            }
          }

          a {
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__xs;
            position: absolute;
            bottom: 24px;
            color: $color-white;
          }
        }
      }
    }
  }
}

.cms-no-route {
  .block {
    &.ves-widget {
      &.content {
        .block-title {
          text-align: center;
          margin-top: 66px;
        }

        .block-content {
          @include lib-css(font-family, $font-family__overpass);
          @include lib-css(font-weight, $font-weight__bold);
          @include lib-font-size($font-size__l);
          margin-top: 40px;
          text-align: center;

          img {
            margin-bottom: 86px;
          }
        }
      }

      &.actions {
        .block-content {

          @include lib-vendor-prefix-display(flex);
          justify-content: center;
          text-align: center;

          a {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-font-size($font-size__m);
            display: inline-block;
            padding: 12px 0;
            width: 164px;
            text-transform: uppercase;
            margin: 0 15px;
          }

          a:first-child {
            @include lib-button-secondary(
              $_button-margin: false
            );
          }

          a:not(:first-child) {
            @include lib-button-primary();
          }
        }
      }
    }
  }
}

@include max-screen($screen__xs) {
  .cms-no-route {
    .block {
      &.ves-widget {
        &.actions {
          .block-content {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
            @include lib-vendor-box-align(center);

            a {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .cms-homepage {
    .block {
      &.block-products-list {
        .widget-product-grid {
          li {
            padding: 0;

            .product-item-info {
              padding: 9px;
              border: 1px solid $color-gray76;
              border-bottom: none;
            }

            .product-item-actions {
              margin: 0 0 10px 0;
            }
          }
        }
      }

      .block-title {
        strong {
          line-height: $line-height__s;
        }
      }
    }

    .homepage-banners {
      .block {
        &.ves-widget {
          .block-content {
            @include lib-font-size($font-size__xl);
          }
        }
      }
    }
  }
}
