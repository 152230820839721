.checkout-index-index{
    .opc-sidebar{
        margin: 50px 0 20px;
    }

    .opc-progress-bar{
        margin-top: 20px;
    }
}
body .authentication-wrapper{
    margin-top: 0;
    @include max-screen($screen__m) {
        float: inherit;
        max-width: 100%;
    }
    .action.action-auth-toggle {
        position: absolute;
        top: 83px;
        right: 0;
        width: 100%;
        line-height: 2.2rem;
        padding: 12px 17px;
        font-size: 1.8rem;
        background: #002C6B;
        border: 2px solid #002C6B;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Overpass", sans-serif;
        font-weight: 700;
        span{
            color: #fff;
            cursor: pointer;
            font-family: "Overpass", sans-serif;
            font-weight: 700;
        }
        @include max-screen($screen__m) {
            position: relative;
            top: inherit;
        }
    }
    .modal-content{
        border: none;
        .modal-header{
            border:none
        }
    }
    
}
.button--postnl-container{
    display: block;
    #postnl-login-button{
        position: absolute;
        right: 0;
        top: 25px;
        width: 100%;
        max-width: 33.2%;
        min-width: 280px;
        border-color: #333;
        background-color: white;
        color: #333;
        transition: .3s;
        &:hover,
        &:focus{
            background-color: white;
            border-color: #f56900;
            color: #f56900;
            #postnl-login-button__outline{
                border: none;
            }
        }
        &:active{
            box-shadow: none;
        }
        #postnl-logo path{
            fill: #f56900;
        }

        @include max-screen($screen__m) {
            position: relative;
            left: 0;
            top: -21px;
            max-width: 100%;
        }

    }
}
