//
//  Product Lists
//  _____________________________________________

.products {
  margin: $indent__l 0;
}

.product {
  &-items {
    @extend .abs-reset-list;
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-prefix-flex-wrap(wrap);
  }

  &-item {
    @extend .abs-add-box-sizing;
    vertical-align: top;
    width: 100%;

    .products-grid & {
      display: inline-block;
      width: 100%;

      &-details {
        margin-top: 15px;
      }
    }

    &-name {
      @include lib-font-size($font-size__m);
      @include lib-css(font-weight, $font-weight__semibold);
      line-height: $line-height__s;
      display: block;
      min-height: 64px;

      a {
        color: $primary__color;
      }
    }

    &-actions {
      display: none;

      .actions-secondary {
        & > .action {
          @include lib-button-checkout();

          &:before {
            margin: 0;
          }

          span {
            @extend .abs-visually-hidden;
          }
        }
      }
    }

    &-description {
      margin: $indent__m 0;
    }

    .primary {
      @include lib-button-addtocart();
      width: 100%;
    }

    .product-reviews-summary {
      .rating-summary {
        margin: 0 4px 0 0;
      }

      .reviews-actions {
        @include lib-font-size($font-size__s);
        margin-top: 5px;
        text-transform: lowercase;
      }
    }

    .price-box {
      @include lib-vendor-prefix-display(flex);
      margin-top: 0;
      margin-bottom: 0;
      color: $theme__primary__color;
      @include lib-vendor-box-align(baseline);

      .price {
        @include lib-font-size($font-size__xxl);
        @include lib-css(font-weight, $font-weight__bold);
      }

      .price-label {
        display: none;
      }
    }

    .special-price,
    .minimal-price {
      .price-wrapper {
        display: inline-block;
      }

      .price-including-tax + .price-excluding-tax {
        display: block;
      }
    }

    .special-price {
      @include lib-vendor-prefix-order(1);
      display: block;
    }

    .old-price {
      margin: 0 5px 0 0;

      .price {
        @include lib-font-size($font-size__m);
        @include lib-css(font-weight, $font-weight__regular);
        text-decoration: line-through;
      }
    }

    .minimal-price {
      .price-container {
        display: block;
      }
    }

    .minimal-price-link {
      margin-top: 5px;
      display: none;

      .price-label {
        color: $link__color;
        @include lib-font-size($font-size__base);
      }

      .price {
        @include lib-css(font-weight, $font-weight__regular);
      }
    }

    .price-excluding-tax,
    .price-including-tax {
      display: block;
      white-space: nowrap;
    }

    .price-from,
    .price-to {
      margin: 0;
    }

    .tocompare {
      @include lib-font-size($font-size__s);
      @include lib-css(font-weight, $font-weight__regular);
      color: $color-gray20;
      display: block;
      margin-top: 15px;
      position: relative;

      span {
        margin-left: 21px;
      }

      &:before {
        @include lib-icon-svg($icon-base-add-to-compare, $color-gray61);
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        margin-top: 0;
      }

      &:hover {
        &:after {
          @include lib-icon-svg($icon-base-check, $color-gray20);
          width: 10px;
          height: 9px;
          display: inline-block;
          left: 3px;
          top: -2px;
          position: absolute;
        }
      }
    }

    .box-tocart {
      margin: 15px -15px 0;

      .tocart {
        white-space: nowrap;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;

        span {
          display: none;
        }

        i {
          &.cart-icon {
            &:before {
              @include lib-icon-svg($icon-base-cart, $color-white);
              display: inline-block;
              height: 22px;
              width: 24px;
              position: relative;
            }
          }

          &.plus-cart-icon {
            &:before {
              @include lib-icon-svg($icon-base-add-to-cart, $color-white);
              display: inline-block;
              height: 11px;
              width: 11px;
              margin-right: 5px;
              position: relative;
            }
          }
        }

        &.disabled {
          i {
            &.cart-icon {
              &:before {
                @include lib-icon-svg($icon-base-cart, $color-white);
                display: inline-block;
                height: 22px;
                width: 24px;
                position: relative;
              }
            }

            &.plus-cart-icon {
              &:before {
                @include lib-icon-svg($icon-base-added-to-cart, $color-white);
                display: inline-block;
                height: 11px;
                width: 11px;
                margin-right: 5px;
                position: relative;
              }
            }
          }
        }
      }
    }

    .product-image-container {
      display: block;
      max-width: 100%;
      position: relative;
      margin: 0 auto;
    }

    .short-description {
      display: none;
    }

    .stock {
      @include lib-font-size($font-size__base);
      @include lib-css(font-family, $font-family__overpass);
      @include lib-css(font-weight, $font-weight__regular);
      color: $color-green2;

      &.available {
        &:before {
          @include lib-icon-svg($icon-base-check-circle, $color-green3);
          width: 12px;
          margin-right: 7px;
          display: inline-block;
        }
      }
    }

    .product-item {
      &-info {
        padding: 30px;
        margin: -10px;
        border: 1px solid $color-white;
      }
    }

    &:hover {
      .product-item {
        &-info {
          box-shadow: 0 0 10px 0 $color-gray-light3;
          border: 1px solid $color-gray-light7;
        }

        &-actions {
          display: block;
          position: absolute;
          width: calc(100% - 9px);
          left: 0;
          z-index: 100;
          margin-top: 10px;
          margin-left: 5px;
          border:1px solid $color-gray-light7;
          border-top: 0;
          box-shadow: 0 2px 8px 0 $theme__transparant-color-dark-middle;
        }
      }
    }

    .qty-plus,
    .qty-minus {
      width: 33.33%;
      height: 40px;
      float: left;
      cursor: pointer;
      text-align: center;

      div {
        width: 12px;
        height: 40px;
        display: inline-block;
      }
    }

    .qty-plus {
      div {
        background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray64)) no-repeat center center;
      }

      &:hover {
        div {
          background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
        }
      }
    }

    .qty-minus {
      div {
        background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray64)) no-repeat center center;
      }

      &:hover {
        div {
          background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
        }
      }
    }

    .fieldset {
      .field {
        &.qty {
          margin-bottom: 0;
        }
      }

      .control {
        &.qty {
          width: 100%;
          margin: 0;
          height: 40px;
          border: 1px solid $color-gray-middle-border;

          .input-text {
            &.qty {
              @include lib-font-size($font-size__m);
              @include lib-css(font-weight, $font-weight__semibold);
              width: 33.33%;
              height: 38px;
              float: left;
              border: 0;
              vertical-align: top;
              margin: 0;
              padding: 0;
              color: $catalog-text-color;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.column.main {
  .product {
    &-items {
      margin-left: -$indent__base;
    }
  }
}

.price-container {
  .price {
    @include lib-font-size($font-size__base);
  }

  .price-including-tax + .price-excluding-tax,
  .weee {
    margin-top: $indent__xs;
  }

  .price-including-tax + .price-excluding-tax,
  .weee,
  .price-including-tax + .price-excluding-tax .price,
  .weee .price,
  .weee + .price-excluding-tax:before,
  .weee + .price-excluding-tax .price {
    @include lib-font-size($font-size__xs);
  }

  .weee {
    &:before {
      content: '('attr(data-label) ': ';
    }

    &:after {
      content: ')';
    }

    + .price-excluding-tax {
      &:before {
        content: attr(data-label) ': ';
      }
    }
  }
}

.products-list {
  .product {
    &-item {
      display: table;
      width: 100%;

      &-info {
        display: table-row;
      }


      &-details {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }
}

.labels-container {
  left: 15px;
  right: auto;
  position: absolute;
  top: 18px;
  z-index: 99;

  .label {
    @include lib-css(font-family, $font-family__overpass);
    @include lib-css(font-weight, $font-weight__bold);
    @include lib-font-size($font-size__xs);
    line-height: $line-height__xl;
    clear: both;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    float: left;
    color: $color-white;
    padding: 3px 23px;
    text-transform: uppercase;
    margin-bottom: 3px;

    &:nth-child(1) {
      background: $product-label-1;
    }

    &:nth-child(2) {
      background: $product-label-2;
    }

    &:nth-child(3) {
      background: $product-label-3;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
  .products-list .product {
    &-item {
      table-layout: fixed;

      &-photo {
        padding: 0 $indent__s $indent__s 0;
        width: 30%;
      }
    }
  }
}

@include max-screen($screen__m) {
  .column.main {
    .products-grid {
      .product-items {
        margin-left:  0;

        li {
          margin-bottom: 15px;
        }
      }
    }
  }

  .product-item,
  .product-item:hover {
    .product-item {
      &-info {
        box-shadow: none;
        border: 0;
        margin: 0;
        padding: 0;
      }

      &-actions {
        position: relative;
        display: block;
        margin: 15px 0;
        box-shadow: none;
        width: 100%;
        border: 0;
      }


    }
  }
}

@include min-screen($screen__s) {
  .product {
    &-item {
      .products-grid & {
        margin-bottom: $indent__l;
      }

      &-actions {

        .products-grid & {
          margin: $indent__s 0;
        }

        .actions-primary + .actions-secondary {
          display: table-cell;
          padding-left: 5px;
          white-space: nowrap;
          width: 50%;
          & > * {
            white-space: normal;
          }
        }

        .actions-primary {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }

  .products-grid .product-item {
    width: 33.3333%;
  }

  .page-products,
  .page-layout-1column,
  .page-layout-3columns,
  .page-products.page-layout-1column,
  .page-products.page-layout-3columns {
    .products-grid {
      .product-item {
        width: 100%;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .page-products {
    .products-grid {
      .product-item {
        width: 100%;
      }
    }
  }

  .page-products.page-layout-1column {
    .products-grid {
      .product-item {
        width: 25%;
      }
    }
  }

  .page-products.page-layout-3columns {
    .products-grid {
      .product-item {
        width: 100%;
      }
    }
  }
}

@include min-screen($screen__l) {
  .products-grid {
    .product-item {
      width: 20%;
    }
  }

  .page-layout-1column {
    .products-grid {
      .product-item {
        width: 16.6666%;
      }
    }
  }

  .page-layout-3columns {
    .products-grid {
      .product-item {
        width: 25%;
      }
    }
  }

  .page-products {
    .products-grid {
      .product-items {
        margin: 0;
      }

      .product-item {
        margin-left: calc((100% - 4 * 23.233%) / 3);
        padding: 0;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
    }
  }

  .page-products {
    &.page-layout-1column {
      .products-grid {
        .product-item {
          margin-left: 0;
          width: 20%;
        }
      }
    }

    &.page-layout-3columns {
      .products-grid {
        .product-item {
          margin-left: 1%;
          width: 32.667%;

          &:nth-child(3n) {
            margin-left: 1%;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}