.footer-usps-wrapper,
.product-list.usp-bar {
    border-bottom: 1px solid $color-gray-light8;

    .row {
      padding: 22px 0;
    }

  ul {
    display: flex;
    width: 100%;
    > li {
      span {
        @include lib-css(font-family, $font-family__overpass);
        @include lib-css(font-weight, $font-weight__semibold);

        &:before {
          display: block;
          @include fa-icon;
          @extend .fas;
          content: fa-content($fa-var-check-circle);
          color: $color-green1;
          float: left;
          margin-right: 17px;
          top: -2px;
          position: relative;
          font-size: $font-size__xxl;
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .footer-usps-wrapper {
    ul {
      > li {
        span {
          display: inline-grid;

          &:before {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
