.footer {
  &-qualitymarks-wrapper {
    min-height: 84px;
    border-bottom: 1px solid $color-gray-light8;

    .footer-content {
      padding: 18px 0 0 0;
      text-align: center;
    }

    .qualitymarks-images {
      margin-bottom: 15px;
      img {
        padding: 0 15px;
      }
    }
  }
}

@include max-screen($screen__m) {
  .footer {
    &-qualitymarks-wrapper {
      .qualitymarks-images {
        img {
          padding: 15px;
        }
      }
    }
  }
}