.homepage-blok {
    .subtitle {
        &.kiyoh-container {
            @include lib-vendor-prefix-display(flex);
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__l);
            @include lib-css(font-weight, $font-weight__semibold);
            margin-top: -40px;
            line-height: $line-height__xl;
            background: $theme__secondary__color;
            border-color: $color-gray-light0;
            position: relative;
            padding: 0 26px;
            height: 94px;
            align-items: center;

            > div {
                padding-right: 62px;
            }

            a {
                @include lib-font-size($font-size__base);
                line-height: $line-height__l;
                display: block;
                color: $color-white;

                .highlight {
                    line-height: $line-height__xs;
                    color: $color-gray20;
                }

                .fa {
                    display: none;
                }
            }

            .usp-container-rating {
                @include lib-font-size($font-size__highlight);
                line-height: $line-height__xs;
                position: absolute;
                display: block;
                right: 0;
                top: 6px;
                height: auto;
                padding-top: 0;
                padding-right: 16px;
                @include lib-css(font-weight, $font-weight__bold);

                span {
                    @include lib-font-size($font-size__highlight);
                    position: absolute;
                    margin-left: -5px;
                    line-height: $line-height__xs;
                }

                sup {
                    top: 0;
                    font-size: 48%;
                    margin-left: 8px;
                }
            }
        }
    }
}

.footer {
    &-kiyoh-wrapper {
        @include lib-css(font-family, $font-family__overpass);
        color: $color-gray20;
        text-align: center;
    }
}

@include max-screen($screen__m) {
    .homepage-blok {
        .subtitle {
            &.kiyoh-container {
                min-height: 94px;
                padding: 10px 15px;
                height: 100%;
            }
        }
    }
}
