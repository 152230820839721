$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: '' !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: '' !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: '' !default;
$product-grid-items-per-row-layout-3-screen-l: '' !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 26px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

$catalog-title-color-dark: $color-gray20;
$catalog-background-color-dark: $color-gray20;
$catalog-background-color-lighten: $color-gray-light0;
$catalog-background-color-light: $color-white;
$catalog-text-color: $color-gray20;
$catalog-text-color-light: $color-white;
$catalog-border-color: $color-gray78;
$catalog-border-color-dark: $color-gray20;
$catalog-border-color-middle: $color-gray89;
$catalog-border-color-lighten: $color-gray82;
$catalog-border-color-light: $color-gray-light0;

@import 'module/listings';
@import 'module/product-detail';
@import 'module/checkout';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
    float: left;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child {
                    &:after {
                        content: ')';
                    }
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    @include lib-css(font-weight, $font-weight__bold);
}

.product {
    &.name {
        a {
            @extend .abs-product-link;
        }
    }

    &.media {
        position: relative;

        .labels-container {
            position: absolute;
            top: 15px;
            right: 0;
            z-index: 99;

            .label {
                @include lib-font-size($font-size__s);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(padding, 6px 18px);
                line-height: $line-height__xl;
                float: right;
                clear: both;
                color: $color-white;
                margin-bottom: 10px;
                border-top-left-radius: 17px;
                border-bottom-left-radius: 17px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

.category-additional-content,
.category-description {
    p {
        @include lib-font-size($font-size__m);
        line-height: $line-height__l;
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
    }

    &-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.product {
    &.media {
        .product {
            &.photo {
                .photo {
                    &.image {
                        @extend .abs-adaptive-images-centered;
                    }
                }
            }
        }

        .placeholder {
            .photo {
                &.container {
                    max-width: 100%;
                }
            }
        }

        .notice {
            @include lib-css(color, $text__color__muted);
            @include lib-font-size($font-size__s);
            margin: $indent__s 0;
        }

        .product {
            &.thumbs {
                margin: $indent__base 0 $indent__l;
            }
        }

        .items {
            &.thumbs {
                @include lib-list-inline();

                .active {
                    display: block;
                    line-height: 1;
                }
            }
        }
    }
}

.product {
    &.info {
        &.detailed {
            clear: both;
            margin-bottom: 30px;
            @include lib-vendor-prefix-display(flex);

            .additional-attributes {
                width: auto;
                @include lib-table-resize(
                    $_th-padding-left: 0,
                    $_th-padding-right: $indent__l,
                    $_th-padding-bottom: $indent__s,
                    $_td-padding-bottom: $indent__s
                );
            }
        }
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            @include lib-css(font-weight, $font-weight__regular);
            display: inline-block;
            margin-right: $indent__base;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                @include lib-css(color, $text__color__muted);
                display: inline-block;
                vertical-align: top;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .product-reviews-summary {
        .reviews-actions {
            @include lib-font-size($font-size__base);
        }
    }

    &-wrapper {
        &.fixed {
            position: fixed;
            left: 0;
            right: 0;
            z-index: 999;
            top: 80px;
            height: 80px;
            background-color: $catalog-background-color-lighten;

            .page-main {
                @include lib-vendor-prefix-display(flex);
                align-items: center;
                padding: 0;
                margin-top: 0;
                height: 80px;
            }

            .marketingrule {
                display: none;
            }

            .product-info-price {
                .price-box {
                    &.price-final_price {
                        @include lib-vendor-prefix-display(flex);
                        @include lib-vendor-prefix-flex-direction(row-reverse);
                    }
                }

                .price-box {
                    .special-price {
                        .price {
                            &.price {
                                @include lib-font-size($font-size__xxxl);
                                @include lib-css(font-weight, $font-weight__bold);
                                @include lib-css(font-family, $font-family__overpass);
                            }
                        }
                    }
                }

                .old-price {
                    @include lib-vendor-prefix-display(flex);
                    align-items: flex-end;
                }
            }

            .product-add-form {
                .box-tocart {
                    margin: 0;
                    width: 100%;

                    .actions {
                        width: 100%;

                        .tocart {
                            margin-right: 0;
                        }
                    }

                    .field.qty {
                        display: none;
                    }
                }
            }

            h1 {
                &.page-title {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(font-family, $font-family__overpass);
                    color: $catalog-title-color-dark;
                    margin: 0;
                }
            }

            .product-options-wrapper {
                display: none;
            }

            .action {
                &.primary {
                    &.tocart {
                        display: none;

                        &.hidden {
                            &.sticky-button-to-top {
                                display: block !important;
                            }
                        }
                    }
                }
            }

            .fixed-column {
                &-title {
                    h1 {
                        &.page-title {
                            @include lib-font-size($font-size__m);
                        }
                    }
                }

                &-img {
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center right;
                }
            }
        }
    }

    .usp-container {
        margin-top: 7px;
        display: inline-block;

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__xl;
                padding-left: 30px;
                margin-bottom: 8px;
                background-image: url(lib-icon-svg-url($icon-base-usp, $theme__primary__color));
                background-size: 18px 18px;
                background-repeat: no-repeat;
                background-position: 0px 5px;
            }
        }

        .usp-title {
            @include lib-font-size($font-size__m);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__s;
            color: $theme__primary__color;
            margin-bottom: 16px;
        }
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size($font-size__s);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size($font-size__s);
                @include lib-css(font-weight, $font-weight__bold);
            }
        }

        .price-wrapper {
            .price {
                @include lib-font-size($font-size__xxl);
                @include lib-css(font-weight, $font-weight__bold);
                color: $theme__primary__color;
            }
        }

        .price {
            white-space: nowrap;
        }

        .old-price,
        .special-price {
            margin: 0;

            .price-label {
                display: none;

                &:after {
                    content: ': ';
                }
            }
        }

        .old-price {
            margin-right: 10px;

            .price {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
            }
        }
    }

    .special-price {
        display: inline-block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size($font-size__base);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        margin: $indent__base 0;

        .field {
            &.qty {
                width: 22%;
                margin-right: 2.23%;
                height: 45px;
                float: left;
                margin-bottom: 10px;

                .control {
                    width: 100%;
                    margin: 0;
                    height: 45px;
                    border: 1px solid $catalog-border-color;

                    .qty-plus,
                    .qty-minus {
                        width: 33.33%;
                        height: 43px;
                        float: left;
                        cursor: pointer;
                        text-align: center;

                        div {
                            width: 12px;
                            height: 43px;
                            display: inline-block;
                        }
                    }

                    .qty-plus {
                        div {
                            background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray64)) no-repeat center center;
                        }

                        &:hover {
                            div {
                                background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
                            }
                        }
                    }

                    .qty-minus {
                        div {
                            background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray64)) no-repeat center center;
                        }

                        &:hover {
                            div {
                                background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
                            }
                        }
                    }

                    .input-text {
                        &.qty {
                            @include lib-font-size($font-size__m);
                            @include lib-css(font-weight, $font-weight__bold);
                            width: 33.33%;
                            height: 43px;
                            float: left;
                            border: 0;
                            vertical-align: top;
                            margin: 0;
                            padding: 0;
                            color: $catalog-text-color;
                        }
                    }
                }

            }
        }

        .input-text {
            &.qty {
                $tocart-input-size: $button__line-height__l + 30px;
                height: $tocart-input-size;
                text-align: center;
                width: $tocart-input-size;
            }
        }

        .actions {
            float: left;
            width: 75%;
            max-width: none;
            padding: 0;
        }

        .action {
            &.tocart {
                @include lib-button-addtocart();
                width: 100%;
                height: 45px;

                span {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__bold);
                    background: url(lib-icon-svg-url($icon-base-cart, $color-white)) no-repeat center right;
                    padding-right: 34px;
                }
            }
        }
    }

    .product-addto-links {
        margin: $indent__base 0;

        a {
            &.action {
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                display: block;
                min-height: 18px;
                padding-left: 26px;
                margin-bottom: 12px;
                text-transform: none;
                border: none;

                span {
                    color: $catalog-text-color;
                }

                &:hover {
                    border: none;
                    background-color: transparent;
                }

                &.tocompare {
                    background: transparent url(lib-icon-svg-url($icon-base-add-to-compare, $color-gray61)) no-repeat 2px 4px;
                    background-size: 16px 16px;
                }
            }
        }
    }

    .action {
        &.tocompare {
            @extend .abs-action-addto-product;
            vertical-align: top;
        }
    }

    .marketingrule {
        @include lib-font-size($font-size__base);
        @include lib-css(font-weight, $font-weight__regular);
        line-height: $line-height__s;
        font-style: italic;
        margin-top: 11px;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    margin: $indent__s 0;

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__bold);
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block {
    &.related {
        border-top: 1px solid $color-gray64;

        .action {
            text-align: center;

            &.select {
                margin: 0 $indent__xs;
            }
        }
    }
}

.product-usps {
    margin-top: 37px;
}

.page-wrapper {
    > .page-bottom {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }

    .product {
        &.info {
            &.detailed {
                border-top: 1px solid $catalog-border-color-light;
                border-bottom: 1px solid $catalog-border-color-light;

                &.fixed {
                    background-color: $catalog-background-color-light;
                    position: fixed;
                    width: 100%;
                    z-index: 999;
                    top: 60px;

                    &.with-addtocart {
                        top: 160px;
                    }

                    .page-main {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .all-specs,
    .product-info-details-container {
        margin-bottom: 38px;

        ul {
            &.tab-group {
                list-style-type: none;
                margin: 0;
                padding-left: 0;

                li {
                    float: left;
                    display: inline-block;
                    margin: 8px 30px 8px 0;

                    a {
                        @include lib-css(font-family, $font-family__overpass);
                        @include lib-font-size($font-size__base);
                        @include lib-css(font-weight, $font-weight__regular);
                        line-height: $line-height__xl;
                        display: block;
                        color: $catalog-text-color;
                    }
                }

                li {
                    &.active,
                    &:hover {
                        a {
                            color: $theme__primary__color;
                        }
                    }
                }
            }
        }

        ul {
            &.product-advantage {
                list-style-type: none;
                padding-left: 0;

                li {
                    @include lib-css(font-family, $font-family__overpass);
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__regular);
                    line-height: $line-height__s;
                    padding-left: 33px;
                    background-image: url(lib-icon-svg-url($icon-base-check, $color-green2));
                    background-repeat: no-repeat;
                    background-size: 16px 22px;
                    background-position: 2px 0;
                    margin-bottom: 6px;
                }
            }
        }

        .detail-title {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__xl);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__s;
            color: $catalog-title-color-dark;
            margin-bottom: 18px;
            margin-top: 30px;
        }

        .product {
            &.attribute {
                @include lib-font-size($font-size__m);
                line-height: $line-height__l;
            }
        }

        ul {
            &.additional-attributes {
                list-style-type: none;
                padding-left: 0;
                margin-left: 0;
                margin-bottom: 26px;

                li {
                    div {
                        @include lib-css(font-family, $font-family__overpass);
                        @include lib-font-size($font-size__m);
                        @include lib-css(font-weight, $font-weight__regular);
                        @include lib-vendor-prefix-display(flex);
                        line-height: $line-height__l;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    span {
                        word-break: break-all;
                    }
                }

                li {
                    &:nth-child(odd) {
                        background-color: $catalog-background-color-lighten;
                    }
                }

                li {
                    @include lib-vendor-prefix-display(flex);
                    margin-bottom: 0;
                    padding: 5px 0 6px 0;

                    .info-icon {
                        width: 16px;
                        background-image: url(lib-icon-svg-url($icon-base-exclamation-circle, $theme__primary__color));
                        background-repeat: no-repeat;
                        background-size: 16px;
                        background-position: 0;
                        height: 16px;
                        display: inline-block;
                        cursor: pointer;
                        flex: 0 0 16px;
                        margin-top: 4px;
                        margin-right: 10px;

                        .tooltip-popup {
                            display: none;
                        }

                        &.no-tooptip {
                            background: none;
                            cursor: auto;
                        }

                        &:hover {
                            .tooltip-popup {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .tooltip-popup {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            position: absolute;
            background: $theme__primary__color;
            border: 1px solid $catalog-border-color-dark;
            padding: 10px;
            z-index: 99;
            top: 0;
            left: 37px;
            width: 100%;
            line-height: $line-height__l;

            p {
                @include lib-css(font-family, $font-family__overpass);
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__l;
                margin-bottom: 0;
            }
        }

        a {
            &.more-attributes {
                @include lib-css(font-family, $font-family__overpass);
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__s;
                text-decoration: underline;
                color: $theme__primary__color;
            }
        }

    }
}

.page-main {
    .all-specs {
        &.page-main {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.catalog-product-view {
    h1 {
        &.page-title {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__l;
            margin-bottom: 10px;
            margin-top: 20px
        }
    }

    .short-description {
        @include lib-css(font-family, $font-family__overpass);
        @include lib-font-size($font-size__m);
        @include lib-css(font-weight, $font-weight__regular);
    }

    .usp-help {
        background-color: $theme__primary__color;
        color: $catalog-text-color-light;
        padding: 24px 32px;
        margin-top: 73px;
        border-radius: 10px;

        .title {
            @include lib-css(font-family, $font-family__overpass);
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__l;
            margin-bottom: 17px;
            position: relative;
            z-index: 1;
        }

        ul {
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 1;

            li {
                @include lib-vendor-prefix-display(flex);
                margin-bottom: 15px;
                font-family: "Overpass", sans-serif !important;
                a {

                    color: $catalog-text-color-light;
                    text-decoration: underline;
                }
            }
        }

        .icon {
            height: 20px;

            &:before {
                position: relative;
                top: -4px;
                display: inline-block;
                width: 23px;
                margin-right: 20px;
            }

            &-chat {
                &:before {
                    @include lib-icon-svg($icon-base-chat, $color-white);
                }
            }

            &-call {
                &:before {
                    @include lib-icon-svg($icon-base-phone, $color-white);
                }
            }

            &-mail {
                &:before {
                    @include lib-icon-svg($icon-base-mail, $color-white);
                }
            }

            &-headset {
                &:before {
                    @include lib-icon-svg($icon-base-headset, $color-white);
                }
            }

            &-info {
                &:before {
                    @include lib-icon-svg($icon-base-info, $color-white);
                }
            }
        }

        img {
            position: absolute;
            right: 37px;
            bottom: 0;
        }
    }

    .brands-container {
        position: absolute;
        right: 20px;
        z-index: 9;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size($font-size__s);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee {
                &:before {
                    display: inline-block;
                }
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .catalog-product-view {
        .brands-container {
            .brand-image {
                max-width: 80px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .catalog-product-view {
        .column {
            &.main {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-prefix-flex-direction(column);
            }
        }

        .product {
            &.media {
                @include lib-vendor-prefix-order(-1);
                margin-top: 130px;
            }
        }
    }

    .product-info-main {
        .box-tocart {
            overflow: hidden;

            .actions {
                .action {
                    &.tocart {
                        @include lib-button-addtocart();
                    }
                }
            }
        }
    }

    .block {
        &.related {
            .action {
                &.select {
                    display: block;
                    margin: $indent__xs 0;
                }
            }
        }
    }

    .compare {
        display: none;
    }

    .product-addto-links {
        .action {
            &.tocompare {
                display: none;
            }
        }
    }

    .product-item-actions {
        .actions-secondary {
            > .action {
                &.tocompare {
                    display: none;
                }
            }
        }
    }

    [class*='block-compare'] {
        display: none;
    }

    .brands-container {
        &.no-thumbnails,
        &.have-thumbnails {
            bottom: 20px;
        }

        .brand-image {
            max-width: 80px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field {
                &.qty {
                    display: table-cell;
                }
            }

            .actions {
                display: table-cell;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }

    .brands-container {
        &.no-thumbnails {
            bottom: 20px;
        }

        &.have-thumbnails {
            bottom: 100px;
        }

        .brand-image {
            max-width: 125px;
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product {
        &.media {
            float: left;
            margin-bottom: $indent__m;
        }
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }

        .product {
            &.media {
                width: 57%;
            }
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product {
            &.media {
                width: 50%;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body {
    &.catalog-product-compare-index {
        .action {
            &.print {
                float: right;
                margin: 15px 0;
            }
        }
    }
}

.table-wrapper {
    &.comparison {
        clear: both;
        max-width: 100%;
        overflow-x: auto;
    }
}

.table-comparison {
    table-layout: fixed;

    .cell {
        &.label {
            &.remove,
            &.product {
                span {
                    @extend .abs-visually-hidden;
                }
            }
        }
    }

    td {
        &:last-child {
            border-right: $table__border-width $table__border-style $table__border-color;
        }
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute {
            &.value {
                overflow: hidden;
                width: 100%;
            }
        }

        &.product {
            &.info,
            &label {
                border-bottom: $table__border-width $table__border-style $table__border-color;
            }
        }

        &.label {
            border-right: $table__border-width $table__border-style $table__border-color;

            .attribute {
                &.label {
                    display: block;
                    width: 100%;
                    word-wrap: break-word;
                }
            }
        }

        &.attribute {
            @include lib-font-size($font-size__s);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product {
        &.rating {
            display: block;
            margin: 15px 0;
        }
    }

    .product-addto-links {
        margin-top: 15px;

        .action {
            &.split,
            &.toggle {
                @include lib-button-s();
            }
        }

        .action {
            &.toggle {
                padding: 0;
            }
        }
    }

    .cell {
        &.remove {
            padding-bottom: 0;
            padding-top: 0;
            text-align: right;

            .action {
                &.delete {
                    @extend .abs-remove-button-for-blocks;
                }
            }
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison {
    &.headings {
        @include lib-css(background, $page__background-color);
        left: 0;
        position: absolute;
        top: 0;
        width: auto;
        z-index: 2;
    }
}

//
//  Filters on category view
//  ---------------------------------------------

.catalog-category-view {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 7px;
            color: $catalog-title-color-dark;

            span {
                @include lib-font-size($font-size__xxxl);
                @include lib-css(font-weight, $font-weight__bold);
            }
        }
    }

    .sidebar {
        .block {
            &-title {
                border: none;
                margin-bottom: 0;

                > strong {
                    @include lib-heading(h4);
                    margin: 0 0 $indent__s;
                    word-break: break-all;
                }
            }

            &-compare {
                .product-item-name {
                    margin-left: 22px;
                }

                .action {
                    &.delete {
                        @extend .abs-remove-button-for-blocks;
                        left: -6px;
                        position: absolute;
                        top: 0;
                    }

                    &.compare {
                        @extend .abs-revert-secondary-color;
                    }

                    &.primary {
                        @include lib-button-checkout();
                    }
                }

                .counter {
                    @extend .abs-block-items-counter;
                }

                .actions-toolbar {
                    margin: 17px 0 0;

                    .secondary {
                        float: left;
                    }
                }
            }
        }
    }
}

.sorter-action {
    &:before {
        @include lib-icon-svg($icon-base-arrow-up, $color-white);
    }
}

.product-image-photo{
    max-width: 178px;
    max-height: 178px;
    width: auto;
    height: auto;
}

.product-list {
    &.usp-bar {
        border: 0;
        padding: 0;
        margin: 40px 0;
        background: $color-gray-light0;

        .row {
            width: 100%;
            background: $color-gray-light0;
        }

        ul {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            @include lib-vendor-prefix-display(flex);
            margin-bottom: 0;
            justify-content: center;
        }

        span {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__bold);
        }
    }
}

.block-viewed-products-grid {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
}

.block-products-list {
    &.grid {
        .block-content {
            padding-top: 30px;
            padding-bottom: 70px;
            display: inline-block;
        }
    }
}

.stock {
    @include lib-css(font-family, $font-family__overpass);
    @include lib-css(font-weight, $font-weight__regular);

    &.available {
        &:before {
            @include lib-icon-svg($icon-base-check-circle, $color-green3);
            width: 12px;
            margin-right: 7px;
            display: inline-block;
        }
    }
}

@include max-screen($screen__m) {
    .product-list {
        &.usp-bar {
            ul {
                @include lib-vendor-prefix-flex-direction(column);
            }

            li {
                width: 100%;
                margin-bottom: 10px;
                justify-content: flex-start;
            }
        }
    }
}

.fotorama__wrap {
    -webkit-text-size-adjust: 100%;
    direction: ltr;
    position: relative;
    z-index: 0;

    .fotorama__stage {
        &:after {
            @include lib-icon-svg($icon-base-magnifyer, $theme__primary__color);
            font-size: 25px;
            position: absolute;
            width: auto;
            left: 49%;
            bottom: 20px;
        }

        .fotorama__caption {
            display: none;
        }
    }
}