//  Newsletter subscription
.block {
  &.newsletter {
    margin-bottom: 0;

    .form {
      &.subscribe {
        display: table;
        width: 100%;
      }
    }

    .fieldset {
      display: table-cell;
      margin: 0;
      padding: 0;
      vertical-align: top;
    }

    .field {
      margin: 0;

      .control {
        @include lib-vendor-prefix-display(flex);
        &:hover {
          color: $color-white;
        }
      }

      > .row {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(center);
      }
    }

    input {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-font-size($font-size__base);
      @include lib-input-placeholder($color-gray46);
      height: 40px;
      max-width: 332px;
      margin: 20px 0;
      padding-left: 20px;
      border: none;
      color: $color-gray46;
    }

    div.mage-error {
      border: 1px solid $color-red10;
      padding: 10px 20px;
      background-color: $color-white;
      width: 160px;
      position: absolute;
      left: 500px;
      top: 14px;

    }

    .title {
      display: none;
    }

    .newsletter-text {
      @include lib-css(font-family, $font-family__overpass);
      @include lib-css(font-weight, $font-weight__semibold);
      @include lib-font-size($font-size__l);
      @include lib-vendor-prefix-display(flex);
      color: $color-white;
    }

    .label {
      @extend .abs-visually-hidden;
    }

    .actions {
      display: table-cell;
      vertical-align: top;
      width: 1%;
    }

    .action {
      &.subscribe {
        @include lib-css(font-family, $font-family__overpass);
        @include lib-css(font-weight, $font-weight__semibold);
        height: 40px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin: 20px 0 20px -4px;
        padding: 7px 37px;
        border: none;
        background: $color-gray-darken5;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
  .block.newsletter {
    width: 100%;

    .field {
      margin-right: 5px;

      .control {
        width: 100%;
      }
    }
  }
}

@include max-screen($screen__m) {
  .block {
    &.newsletter {
      .field {
        .control {
          display: block;

          input {
            width: 100%;
            max-width: 100%;

            &.mage-error {
              margin-bottom: 0;
            }
          }

          .primary {
            margin-top: 0;
            margin-left: 0;
            width: 100%;
          }
        }
      }

      div.mage-error {
        position: relative;
        top: 0;
        width: 100%;
        left: 0;
        margin-bottom: 5px;
      }
    }
  }
}