.formbuilder {


  .subtemplate-wrapper {
    > label {
      margin-bottom: 0px;
    }
  }

  .required {
    em {
      color: $color-gray20;
    }
  }

  .button {
    @include lib-css(font-family, $font-family__overpass);
    background: $theme__primary__color;
    border: 2px solid $theme__primary__color;
    color: $color-white;
  }
}