.cms-contact {
  .block {
    &.ves-widget {
      .block-title {
        strong {
          @include lib-font-size($font-size__xxl);
          @include lib-css(font-weight, $font-weight__semibold);
        }
      }
    }

    &.contact-sm {
      .block-content {
        img {
          margin-right: 10px;
        }
      }
    }
  }

  .contact-right-form {
    h2 {
      @include lib-font-size($font-size__xxl);
      @include lib-css(font-family, $font-family__overpass);
      @include lib-css(font-weight, $font-weight__semibold);
      line-height: $font-size__s;
      text-align: left;
      margin-top: 4px;
    }
  }
}

@include max-screen($screen__m) {
  .contact-right-form {
    margin-top: 40px;
  }

}